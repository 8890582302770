var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',{staticClass:"card-signup header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0 }}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/images/logo.jpg"),"alt":"logo"}})]),_c('h5',{staticClass:"font-semibold text-center"},[_vm._v(_vm._s(_vm.$t('reset_password.title')))]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'password',
                        {
                            rules: [
                                { required: true, message: _vm.$t('form.password_required') },
                                { validator: _vm.checkPassword } ],
                        } ]),expression:"[\n                        'password',\n                        {\n                            rules: [\n                                { required: true, message: $t('form.password_required') },\n                                { validator: checkPassword },\n                            ],\n                        },\n                    ]"}],attrs:{"type":"password","placeholder":_vm.$t('form.password')}})],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'confirm',
                        {
                            rules: [
                                { required: true, message: _vm.$t('form.confirm_password_required') },
                                { validator: _vm.compareToFirstPassword } ],
                        } ]),expression:"[\n                        'confirm',\n                        {\n                            rules: [\n                                { required: true, message: $t('form.confirm_password_required') },\n                                { validator: compareToFirstPassword },\n                            ],\n                        },\n                    ]"}],attrs:{"type":"password","placeholder":_vm.$t('form.confirm_password')},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button text-uppercase",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('form.confirm'))+" ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":{ path: ("/sign-in/" + _vm.companyCode) }}},[_vm._v(" "+_vm._s(_vm.$t('go_back'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }