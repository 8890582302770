<template>
    <div>
        <a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{ paddingTop: 0 }">
            <div class="logo">
                <img src="../../assets/images/logo.jpg" alt="logo" />
            </div>
            <h5 class="font-semibold text-center">{{ $t('reset_password.title') }}</h5>
            <a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit">
                <a-form-item>
                    <a-input-password
                        v-decorator="[
                            'password',
                            {
                                rules: [
                                    { required: true, message: $t('form.password_required') },
                                    { validator: checkPassword },
                                ],
                            },
                        ]"
                        type="password"
                        :placeholder="$t('form.password')"
                    >
                    </a-input-password>
                </a-form-item>
                <a-form-item>
                    <a-input-password
                        v-decorator="[
                            'confirm',
                            {
                                rules: [
                                    { required: true, message: $t('form.confirm_password_required') },
                                    { validator: compareToFirstPassword },
                                ],
                            },
                        ]"
                        type="password"
                        :placeholder="$t('form.confirm_password')"
                        @blur="handleConfirmBlur"
                    />
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" block html-type="submit" class="login-form-button text-uppercase">
                        {{ $t('form.confirm') }}
                    </a-button>
                </a-form-item>
            </a-form>
            <p class="font-semibold text-muted text-center">
                <router-link :to="{ path: `/sign-in/${companyCode}` }" class="font-bold text-dark">
                    {{ $t('go_back') }}
                </router-link>
            </p>
        </a-card>
    </div>
</template>

<script>
import md from 'md5';
import { HTTP } from '../../config/http-common';
import { mixinToasts, mixinLoadings } from '../../mixins';

export default {
    mixins: [mixinToasts, mixinLoadings],
    components: {},
    data() {
        return {
            form: this.$form.createForm(this, { name: 'reset-form' }),
            confirmDirty: false,
            companyCode: this.$route.params.company_code,
        };
    },
    methods: {
        handleConfirmBlur(e) {
            const value = e.target.value;
            this.confirmDirty = this.confirmDirty || !!value;
        },

        checkPassword(rule, value, callback) {
            const pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
            if (value) {
                if (!pattern.test(value)) {
                    callback(this.$t('form.incorrect_password_format'));
                } else if (this.confirmDirty) {
                    this.form.validateFields(['confirm'], { force: true });
                }
                callback();
            } else {
                callback();
            }
        },

        compareToFirstPassword(rule, value, callback) {
            const form = this.form;
            if (value && value !== form.getFieldValue('password')) {
                callback(this.$t('form.incorrect_confirm_password'));
            } else {
                callback();
            }
        },

        // Handles input validation after submission.
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.showLoading();
                    const token = this.$route.query.token;
                    HTTP.post(
                        '/auth/reset_password',
                        {
                            new_password: md(values.password),
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    )
                        .then((res) => {
                            setTimeout(() => {
                                this.hideLoading();
                                this.showToast('success', this.$t('reset_password.text_success'));
                                this.$router.push(`/sign-in/${this.companyCode}`);
                            }, 500);
                        })
                        .catch((error) => {
                            this.hideLoading();
                            this.showToast('error', this.$t('error.link_has_expired'));
                        });
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.card-signup {
    margin: 0 auto;
    & .logo {
        text-align: center;
        margin-bottom: 40px;
    }
}
</style>
